import React, { useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'twin.macro'
import Cookies from 'js-cookie'

import { queryWordPress } from '../api'

const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [slides, setSlides] = useState([])

  useEffect(() => {
    const locationCookie = Cookies.get('localResidentialBranchData')
    const userState = locationCookie
      ? JSON.parse(locationCookie).serviceAreaMeta.serviceAreaBranch[0]
          .branchMeta.branchOfficeState
      : null
    const whereClause = userState
      ? `(where: { testimonialState: "${userState}"})`
      : ''

    queryWordPress(`{
            testimonials${whereClause} {
                nodes {
                    title
                    content
                    testimonialMeta {
                        testimonialCity
                        testimonialState
                    }
                    featuredImage {
                        node {
                            sourceUrl(size: MEDIUM)
                        }
                    }
                }
            }
        }`).then(({ data }) => setSlides(data?.testimonials?.nodes))
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeSlide < slides.length - 1) {
        setActiveSlide(activeSlide + 1)
      } else {
        setActiveSlide(0)
      }
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [slides, activeSlide])

  return !slides ? null : (
    <section tw="lg:(px-32) pt-16">
      <div css={['height: 22rem;', tw`relative md:(h-48) mb-8`]}>
        {slides.map((testimonial, i) => {
          const active = activeSlide === i
          return (
            <div
              css={[
                tw`flex w-full items-center absolute top-0 left-0 flex-col md:(flex-row)`,
                active && tw``,
              ]}
            >
              <div tw="mb-2 md:(mb-0 mr-12)">
                <img
                  src={testimonial.featuredImage.node.sourceUrl}
                  css={[
                    'max-width:200px;',
                    tw`object-contain rounded-full transform translate-y-3 opacity-0 transition ease-in-out duration-500`,
                    active && tw`opacity-100 translate-y-0 opacity-100`,
                  ]}
                />
              </div>
              <blockquote
                css={[
                  tw`col-span-2 transform -translate-x-3 opacity-0 transition ease-in-out duration-500 text-center md:(text-left)`,
                  active && tw`opacity-100 translate-x-0 opacity-100`,
                ]}
              >
                <p
                  tw="md:(text-xl) mb-4 leading-snug"
                  dangerouslySetInnerHTML={{
                    __html: testimonial.content,
                  }}
                />
                <cite tw="md:(text-lg) not-italic">
                  – <strong>{testimonial.title}</strong>{' '}
                  {testimonial.testimonialMeta.testimonialCity},{' '}
                  {testimonial.testimonialMeta.testimonialState}
                </cite>
              </blockquote>
            </div>
          )
        })}
      </div>
      <div tw="flex items-center justify-center">
        {slides.map((slide, i) => {
          const active = activeSlide === i
          return (
            <button
              onClick={() => setActiveSlide(i)}
              css={[
                tw`w-5 h-5 border-4 border-yellow-600 mr-2 rounded-full focus:(outline-none) transition ease-in-out duration-150`,
                active && tw`bg-yellow-600`,
              ]}
            />
          )
        })}
      </div>
    </section>
  )
}

export default {
  Carousel,
}
